var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bus-stop ")]),_c('span',{staticClass:"pl-2"},[_vm._v("Stops")]),_c('v-spacer'),(_vm.role != '1')?_c('create-button',{on:{"create":_vm.createStop}}):_vm._e(),_c('activation-tool-tip',{attrs:{"model":"stops"}}),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","disabled":_vm.isSubmit},on:{"click":_vm.downloadStops}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-file-download")]),_c('span',[_vm._v(" Download All Stops")])],1)],1),_c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.stops,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.routes",fn:function(ref){
var item = ref.item;
return [(item.routes.length)?_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.displayRoutes(_vm.stops.indexOf(item))}}},[_vm._v(_vm._s(item.routes.length))]):_c('span',[_vm._v("No routes")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LL")))]),_vm._v(" - ")]}},{key:"item.school.district",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.school.district)+" ")]}},{key:"item.guardian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.guardian.name || "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewStop(item)}}},[_vm._v(" mdi-eye ")]),(_vm.role != '1')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editStop(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.deleteStop(item, _vm.stops.indexOf(item))}}},[_vm._v(" mdi-delete ")])]}}])})],1),(_vm.stops[_vm.selectedStop])?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.stops[_vm.selectedStop].name)+" ")]),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Routes")]),_c('v-list-item-group',_vm._l((_vm.stops[_vm.selectedStop].routes),function(item,i){return _c('v-list-item',{key:i},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eye ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){return _vm.viewRoute(item)}}})],1)],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }